<template>
  <ModuleSubPage fullWidth title="USER_MANAGEMENT.USERS.LIST.TITLE">
    <BaseHasPermission :permissionNames="['user.read']">
      <v-card>
        <v-card-title>
          <v-row no-gutters>
            <v-col cols="auto" class="mr-3 mb-6 mb-md-0">
              <v-btn icon @click="retrieveUsers" :loading="isLoading" :disabled="isLoading">
                <v-icon small>far fa-sync-alt</v-icon>
              </v-btn>
            </v-col>

            <v-spacer />

            <v-col align-self="center" cols="12" md="5" lg="4" xl="3">
              <BaseTableSearchField
                v-model="filter.search"
                @triggerSearch="retrieveUsers"
                :loading="isLoading"
                :disabled="isLoading"
              />
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-text class="pa-0">
          <v-data-table
            :headers="headers"
            :items="users"
            :loading="isLoading"
            :options.sync="options"
            :server-items-length="meta.total"
            class="elevation-1"
            :footer-props="{
              'items-per-page-options': perPageOptions,
            }"
          >
            <template #[`item.actions`]="{ item }">
              <QuickActions :user="item" @reload="retrieveUsers" />
            </template>

            <template #[`item.roles`]="{ item }">
              <UserRoles :roles="item.roles" />
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </BaseHasPermission>
  </ModuleSubPage>
</template>

<script>
import ModuleSubPage from "@components/Layout/ModuleSubPage.vue";
import QuickActions from "@views/Restricted/UserManagement/User/List/quickActions/QuickActions.vue";
import UserRoles from "./partials/UserRoles.vue";

export default {
  name: "UserList",

  components: {
    ModuleSubPage,
    QuickActions,
    UserRoles,
  },

  data() {
    return {
      isLoading: false,
      users: [],
      perPageOptions: [10, 20, 50, 100],
      options: {
        page: 1,
        perPage: 10,
        sortBy: [],
        sortDesc: [],
      },
      filter: {
        search: null,
      },
      meta: {
        total: 0,
      },
    };
  },

  watch: {
    options: {
      deep: true,
      handler() {
        this.retrieveUsers();
      },
    },
  },

  computed: {
    headers() {
      return [
        {
          text: this.$t("USER_MANAGEMENT.USERS.LIST.TABLE.HEADERS.USERNAME"),
          value: "email",
          sortable: true,
        },
        {
          text: this.$t("USER_MANAGEMENT.USERS.LIST.TABLE.HEADERS.ROLES"),
          value: "roles",
          sortable: false,
        },
        {
          value: "actions",
          sortable: false,
          align: "end",
        },
      ];
    },

    payload() {
      return {
        ...this.filter,
        page: this.options.page,
        perPage: this.options.itemsPerPage,
      };
    },

    isMobile() {
      return this.$vuetify.breakpoint.lgAndDown;
    },
  },

  methods: {
    async retrieveUsers() {
      this.isLoading = true;
      try {
        const { data } = await this.$axios.userManagement.listUsers(this.payload);
        this.users = data.data;
        this.meta = data.meta;

        if (this.options.perPage === -1) {
          this.meta = {
            total: data.data.length,
          };
        }
      } catch (err) {
        console.error(err);
      } finally {
        this.isLoading = false;
      }
    },
  },

  mounted() {
    this.retrieveUsers();
  },
};
</script>
